/* eslint-disable */
window.earlyRequesterHelpers = (function () {
  function getCookies() {
    const REGEXP = /([\w\.]+)\s*=\s*(?:"((?:\\"|[^"])*)"|(.*?))\s*(?:[;,]|$)/g;
    let cookies = {};
    let match;
    while ((match = REGEXP.exec(document.cookie)) !== null) {
      let value = match[2] || match[3];
      cookies[match[1]] = decodeURIComponent(value);
    }
    return cookies;
  }

  function getCookie(name) {
    return getCookies()[name];
  }

  function getHstc() {
    return getCookie('__hstc') || null;
  }

  function getUtk() {
    let utk = getCookie('hubspotutk');
    if (!utk) {
      utk = getHstc();
      if (utk) {
        utk = utk.split('.')[1];
      }
    }
    return utk || null;
  }

  function url(endpoint) {
    return window.quickFetch.getApiUrl('/' + endpoint, true);
  }

  function requestDispatcher(earlyRequestKey, url, type, data) {
    window.quickFetch.makeEarlyRequest(earlyRequestKey, {
      url: url,
      timeout: 15000,
      dataType: 'json',
      contentType: 'application/json',
      type: type,
      data: data,
    });
  }

  // this is to facilitate testing.
  return {
    getUtk,
    getCookie,
    getCookies,
    url,
  };
})();
